import { useGlobalContentStore } from '~/store/globalContent';

export default function useFormatPrice() {
  const globalContentStore = useGlobalContentStore();
  const marketSettings = globalContentStore.marketSettings;

  const formatPrice = (number: number) => {
    if (marketSettings.countryCode === 'NO') {
      const locale = marketSettings.locale as string;
      const formatter = new Intl.NumberFormat(locale);

      return formatter.format(number).replace(/\D00(?=\D*$)/, '') + ' NOK';
    } else {
      const currency = marketSettings.currency.currencyCode as string;
      const locale = marketSettings.locale as string;

      const formatter = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currency,
      });
  
      return formatter.format(number).replace(/\D00(?=\D*$)/, '');
    }
  };
  
  return {
    formatPrice,
  };
}
